.btn-solid {
    background-color: $color-blue;
    border: 1px solid $color-blue;
    outline: none;
    box-shadow: none;
    border-radius: 10px;
    padding: 10px 20px;

    &:hover,
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.btn-outline {
    color: $color-blue;
    background-color: $color-white;
    border: 1px solid $color-blue;
    outline: none;
    box-shadow: none;
    border-radius: 10px;
    padding: 10px 20px;

    &:hover,
    &:focus {
        outline: none;
        box-shadow: none;
    }
}