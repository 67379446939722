@media screen and (max-width: 768px) {
    h1 {
        font-size: 22px;
    }

    a,
    small,
    button,
    .btn {
        font-size: 12px;
    }

    p,
    span,
    input,
    textarea,
    .form-control {
        font-size: 14px;
    }

    ul {
        li {
            font-weight: 400;
            font-size: 14px;
        }
    }

    th,tr,td{
        font-size: 14px;
    }
}