.auth-card {
    width: 510px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin: auto;
    padding: 35px;
    background-color: $color-white;

    .btn-solid {
        padding: 16px;
        font-size: 18px;
    }

    .w-400 {
        font-weight: 400;
    }

    .password-wrapper {
        position: relative;

        .eye-icon {
            position: absolute;
            top: 16px;
            right: 16px;
            cursor: pointer;
        }
    }

    .footer {
        p {
            color: $color-grey;
        }
    }

    .text-grey {
        color: $color-grey;
    }

    a {
        font-size: 16px;
        text-decoration: none;
    }

    &.verification-code-form {
        span {
            font-weight: 500;
            cursor: pointer;
        }

        .otp-input {
            input {
                height: 55px;
                width: 100% !important;
                padding: 0;
                margin: 0 5px;
                border: 1px solid $color-border;
                color: $color-black;
                border-radius: 10px;

                &:focus {
                    border: 1px solid $color-green;
                }
            }
        }

        .timer {
            font-weight: 700;

            &.cursor {
                cursor: pointer;
            }
        }
    }

    &.auth-privacy-policy-page,
    &.auth-terms-and-conditions-page {
        .wrapper {
            height: 580px;
            overflow-y: auto;
            padding-right: 10px;
        }
    }
}