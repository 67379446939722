@media screen and (max-width: 576px) {
    .theme-popup {
        &.notification-popup {
            .modal-content {
                max-width: 100%;
                transform: unset;
            }
        }
    }

    .auth-card {
        &.auth-privacy-policy-page,
        &.auth-terms-and-conditions-page {
            .wrapper {
                height: 350px;
                overflow-y: auto;
                padding-right: 10px;
            }
        }
    }
}