h1 {
    font-weight: 600;
    font-size: 24px;
    margin: 0;
}

h2 {
    font-weight: 600;
    font-size: 18px;
    margin: 0;
}

h6 {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

p {
    font-weight: 400;
    font-size: 16px;
    margin: 0;
}

a {
    font-weight: 600;
    font-size: 12px;
    margin: 0;
}

button,
.btn {
    font-weight: 500;
    font-size: 14px;
    margin: 0;
}

input,
textarea,
.form-control {
    font-weight: 400;
    font-size: 16px;
}

span {
    font-weight: 400;
    font-size: 16px;
}

small {
    font-size: 12px;
    font-weight: 400;
}

ul {
    li {
        font-weight: 400;
        font-size: 16px;
    }
}

th,tr,td{
    font-size: 16px;
}