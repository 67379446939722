.dashboard-layout {
    .navbar {
        height: 70px;

        .logo-img {
            width: 100px;
        }

        a {
            text-decoration: none;
            color: $color-grey;
            margin-right: 20px;

            &:hover,
            &:focus,
            &.active {
                color: $color-blue;

                span {
                    svg {
                        path {
                            fill: $color-blue;
                        }
                    }
                }
            }

            &.logout {
                color: $color-grey !important;

                &:hover,
                &:focus {
                    color: $color-blue !important;
                    ;

                    svg {
                        path {
                            fill: $color-blue !important;
                        }
                    }
                }

                svg {
                    path {
                        fill: $color-grey !important;
                    }
                }
            }
        }

        .navbar-toggler {
            padding: 0;
            border: none;
            outline: none;
            box-shadow: none;

            &:hover,
            &:focus {
                border: none;
                outline: none;
                box-shadow: none;
            }
        }

        .bell-icon-wrapper {
            position: relative;
            &.active{
                &::after {
                    position: absolute;
                    content: "";
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: $color-red;
                    top: 0;
                    left: 9px;
                }
            }
        }

        .bell-icon {
            font-size: 20px;
            margin-right: 20px;
            cursor: pointer;
        }

        .profile-wrapper {
            min-width: 40px;
            width: 40px;
            height: 40px;
            overflow: hidden;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .dropdown-menu {
            &.show {
                position: absolute;
                left: -25px;
                top: 50px;
            }

            .dropdown-item {

                &.active,
                &:hover,
                &:focus {
                    background-color: transparent;
                }

                span {
                    margin-right: 20px;
                }
            }
        }

        .dropdown-toggle {
            &::after {
                display: none;
            }

            span {
                color: $color-black;

                svg {
                    transform: translateY(-1px);
                }
            }
        }
    }
}

.dashboard-offcanvas {
    .offcanvas-body {
        a {
            text-decoration: none;
            color: $color-grey;
            margin-right: 20px;

            &.active,
            &:hover,
            &:focus {
                color: $color-blue;

                span {
                    svg {
                        path {
                            fill: $color-blue;
                        }
                    }
                }
            }
        }

        .logout {
            color: $color-grey !important;

            &:hover,
            &:focus {
                color: $color-blue !important;

                span {
                    svg {
                        path {
                            fill: $color-blue !important;
                        }
                    }
                }
            }

            span {
                svg {
                    path {
                        fill: $color-grey !important;
                    }
                }
            }
        }
    }
}

.setup-page-layout {
    padding-top: 30px;
    padding-bottom: 30px;
    background: $color-body;

    .link-wrapper {
        min-height: calc(100vh - 130px);
        border-radius: 10px;
        padding: 35px 25px;
        background: $color-white;
        box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 6%);
    }
}