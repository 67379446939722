.stats-card {
    padding: 30px 20px;
    border-radius: 12px;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 7%);
    background-color: $color-white;

    &:hover {
        background-color: $color-blue;
        color: $color-white;

        h6 {
            color: $color-white;
        }

        svg {
            rect {
                fill: $color-white;
                fill-opacity: 1;
            }
        }
    }

    h6 {
        color: $color-grey;
        padding-bottom: 5px;
    }
}

.theme-data-table {
    .wrapper {
        box-shadow: 0px 0px 20px 0px #0000000F !important;
        border-radius: 12px;
        padding: 0;
    }

    nav {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    .data-table {
        font-size: 16px;
        font-weight: 400;

        .rdt_TableHead {
            color: $color-grey
        }

        .rdt_TableRow {
            color: $color-black;
        }
    }

    .sc-bcXHqe {
        height: 532px;
    }

    .sc-ilhmMj,
    .sc-lllmON {
        display: none;
    }

    button {

        &#pagination-first-page,
        &#pagination-last-page {
            display: none;
        }
    }

    .btn-delete {
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 0;
        color: $color-blue;
        box-shadow: none;
        margin-bottom: 1rem;

        &:hover,
        &:focus,
        &:active {
            border: none;
            outline: none;
            box-shadow: none;
            color: $color-red;
            background-color: transparent;
        }
    }
}

.information-box {
    background: $color-white;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgb(0 0 0 / 6%);
    min-height: 85vh;

    .info-wrapper {
        span {
            display: block;
            width: 100%;
            color: $color-grey;

            b {
                color: $color-black;
            }
        }
    }
}

.schedule-run-box {
    background: $color-white;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgb(0 0 0 / 6%);
    min-height: 85vh;

    .info-wrapper {
        span {
            color: $color-grey;

            span {
                color: $color-green;
            }
        }
    }
}

.theme-chart {
    canvas {
        width: 100% !important;
    }
}

.themedata-table-action {
    .icons {
        cursor: pointer;
        font-size: 1rem;
        color: $color-black;
    }
}

.custom-search-input {
    .debounce-search-input {
        padding: 8px;
        border-radius: 10px;
        font-family: 'Inter', sans-serif;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.58 19.57c-5.235 0-9.496-4.28-9.496-9.535C.084 4.779 4.345.5 9.58.5c5.234 0 9.496 4.28 9.496 9.535 0 5.256-4.262 9.535-9.496 9.535Zm0-17.675c-4.475 0-8.107 3.656-8.107 8.14s3.632 8.14 8.107 8.14 8.106-3.656 8.106-8.14-3.631-8.14-8.106-8.14ZM19.308 20.5a.686.686 0 0 1-.491-.205l-1.853-1.86a.704.704 0 0 1 0-.986.697.697 0 0 1 .982 0l1.853 1.86a.704.704 0 0 1 0 .986.686.686 0 0 1-.491.205Z' fill='%23515151'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 96%;
    }
}

.theme-status {
    .status-approved {
        background: $color-light-blue;
        color: $color-blue;
        padding: 10px;
    }

    .status-rejected {
        background: $color-light-red;
        color: $color-red;
        padding: 10px;
    }
}

.settings-sidebar {
    min-height: calc(100vh - 180px);
    background-color: $color-white;
    box-shadow: 0 0 20px rgb(0 0 0 / 6%);
    padding: 25px;
    border-radius: 20px;

    .wrapper {
        ul {
            padding: 0;

            li {
                list-style: none;

                a {
                    color: $color-grey;
                    display: block;
                    width: 100%;
                    padding: 15px;
                    border-radius: 10px;

                    &:hover,
                    &.active {
                        color: $color-blue;
                        background-color: $color-mild-blue;

                        span {
                            font-weight: 700;
                        }

                        svg {
                            path {
                                fill: $color-blue;
                            }
                        }
                    }
                }
            }
        }
    }
}

.schedule-table {
    .download-span {
        font-size: 14px;
        cursor: pointer;

        .icon {
            color: $color-grey;
            font-size: 1.5rem;
            margin-right: 5px;
        }
    }

    table {

        th,
        td,
        tr {
            padding: 8px;
            border: 1px solid $color-black
        }

        .days-row {
            background: $color-green;
            color: $color-white;
        }

        .dates-row {
            background: $color-golden;
            color: $color-black;
        }

        .team-competency-row {
            .day-th {
                background: $color-blue;
                color: $color-white;
            }

            .competency-th {
                background: $color-black;
                color: $color-white;
            }
        }

        .posts-row {
            .member-td {
                background-color: $color-sea-blue;
                color: $color-black;
            }
        }

        .fireteam-row {
            .fireteam-th {
                background: $color-dark-red;
                color: $color-white;
            }

            .member-name-td {
                &:nth-child(even) {
                    background-color: $color-dark-red;
                    color: $color-white;
                }

                &:nth-child(odd) {
                    background-color: $color-black;
                    color: $color-white;
                }
            }
        }

        .emt-row {
            .emt-th {
                background: $color-dark-grey;
                color: $color-white;
            }

            .member-name-td {
                &:nth-child(even) {
                    background-color: $color-dark-grey;
                    color: $color-white;
                }

                &:nth-child(odd) {
                    background-color: $color-black;
                    color: $color-white;
                }
            }
        }

        .extra-board-row {
            .extra-board-th {
                background: $color-dark-orange;
                color: $color-white;
            }

            .member-name-td {
                &:nth-child(even) {
                    background-color: $color-dark-orange;
                    color: $color-white;
                }

                &:nth-child(odd) {
                    background-color: $color-black;
                    color: $color-white;
                }
            }
        }

        .vacation-row {
            .vacation-th {
                background: $color-dark-brown;
                color: $color-white;
            }

            .member-name-td {
                &:nth-child(even) {
                    background-color: $color-dark-brown;
                    color: $color-white;
                }

                &:nth-child(odd) {
                    background-color: $color-black;
                    color: $color-white;
                }
            }
        }

        .train-assign-row {
            .train-assign-th {
                background: $color-sea-blue;
                color: $color-white;
            }

            .member-name-td {
                &:nth-child(even) {
                    background-color: $color-sea-blue;
                    color: $color-white;
                }

                &:nth-child(odd) {
                    background-color: $color-black;
                    color: $color-white;
                }
            }
        }

        .slide-to-night-row {
            .slide-to-night-th {
                background: $color-ice-green;
                color: $color-white;
            }

            .member-name-td {
                &:nth-child(even) {
                    background-color: $color-ice-green;
                    color: $color-white;
                }

                &:nth-child(odd) {
                    background-color: $color-black;
                    color: $color-white;
                }
            }
        }
    }
}